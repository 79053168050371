import React, {useState} from 'react';
import Logo from '../../assets/images/logo-transparent.png'
import {
  Nav,
  NavLink,
  Bars,
  NavMenu,
  NavBtn,
  NavBtnLink,
  NavMenuMobile,
} from './NavbarElements';

import ROUTES from '../../navigation/ROUTES';
  
const Navbar = () => {

  function RenderMenuItems() {
    return(
      <>
        <div className='menu_mobile'>
        <NavMenuMobile>
          <NavLink to={ROUTES.HOME} activeStyle>
            HOME
          </NavLink>
          <NavLink to={ROUTES.VARIZES} activeStyle>
            VARIZES E MICROVARIZES
          </NavLink>
          <NavLink to={ROUTES.FACE} activeStyle>
            FACE
          </NavLink>
          <NavLink to={ROUTES.BODY} activeStyle>
            CORPO
          </NavLink>
          <NavLink to={ROUTES.BLOG} activeStyle>
            BLOG
          </NavLink>
          <NavBtnLink style={{backgroundColor: 'white', color: 'black', padding: '10px 20px', margin: '10px', border: 'none'}} to={ROUTES.CONTACT}>Contato</NavBtnLink>
        </NavMenuMobile>
        </div>
      </>
    )
  }

  const [value, setValue] = useState(false);

  return (
    <>
      <Nav>
        <Bars onClick={() => {setValue(!value)}} />
        {value ? RenderMenuItems() : null}
        <img src={Logo} alt='logo-navbar' style={{height: '50px', width: '110px', position: 'absolute', left: '40px', top: '18px'}}/>
        <NavMenu>
          <NavLink to={ROUTES.HOME} activeStyle>
            HOME
          </NavLink>
          <NavLink to={ROUTES.VARIZES} activeStyle>
            VARIZES E MICROVARIZES
          </NavLink>
          <NavLink to={ROUTES.FACE} activeStyle>
            FACE
          </NavLink>
          <NavLink to={ROUTES.BODY} activeStyle>
            CORPO
          </NavLink>
          <NavLink to={ROUTES.BLOG} activeStyle>
            BLOG
          </NavLink>
        </NavMenu>
        <NavBtn>
          <NavBtnLink to={ROUTES.CONTACT}>Contato</NavBtnLink>
        </NavBtn>
      </Nav>
    </>
  );
};
  
export default Navbar;