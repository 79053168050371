import Footer from "../../components/footer/Footer";
import React from 'react';
import Card, {CardSection} from "../../components/card/Card";
import Navbar from '../../components/navbar';
import styled from 'styled-components';


function Contact() {

  const ContactTitle = styled.h2`
    text-align: center !important;
    margin: 6rem 0 0 0 !important;
    text-transform: uppercase;
    color: #AB8A57;
    font-weight: bold;
    font-size: 2em !important;
  `

  const ContactParagraph = styled.p`
    color: #AB8A57 !important;
    margin-top: 20px !important;
    font-size: 18px;
    height: 20% !important;
  `

  return (
    <>
      <Navbar />
      <div>
        <ContactTitle>
          Está com alguma dúvida?
        </ContactTitle>
        <ContactParagraph style={{textAlign: 'center'}}>
          Entre em contato conosco
        </ContactParagraph>
      </div>
      <div>
        <CardSection>
          <Card title="e-mail" paragraph="Se tiver qualquer dúvida, nos mande um email" button="Mandar e-mail" href="Clinicadrrodrigomorais@gmail.com" email={true} />
          <Card title="Telefones" paragraph="Se preferir mandar mensagem no WhatsApp ou ligar!" button="Mandar mensagem" href="https://wa.me/5511996034449" />
          <Card title="Redes sociais" paragraph="Siga nosso trabalho nas redes sociais" type="SocialMedia">

          </Card>
        </CardSection>
      </div>
      <Footer />
    </>
  );
};
  
export default Contact;