export const ROUTES = {
  HOME: "/*",
  BLOG: "/blog",
  CONTACT: "/contato",
  BODY: "/corpo",
  FACE: "/rosto",
  PROFILE: "/perfil",
  VARIZES: "/varizes-e-micro-varizes"
}

export default ROUTES;