import Navbar from '../../components/navbar/Navbar';
import Footer from "../../components/footer/Footer";
import botox from "../../assets/images/botox_new.jpeg";
import preenchimento from "../../assets/images/preenchimentonovo.jpeg";
import faceheader from "../../assets/images/face_header1.jpg";
import Entrecontato from '../../components/entrecontato/Entrecontato';
import Bioestimulador  from '../../assets/images/Bioestimulador.jpeg';
import React from 'react';
  
import './face.css';
const Face = () => {
  return (
    <>
    <Navbar />
    
    <section>
      <div className="image_header_section">
        <img className="image_header" src={faceheader} alt="Varizes Header" style={{objectFit: 'cover', width: '100%', height: '80vh', marginTop: '3px'}}/>
          <h1 className="image_header_title">face</h1>
      </div>
    </section>

    <h1 className="servicos_h1">Conheça alguns dos<br/><b>NOSSOS SERVIÇOS E COMO FUNCIONAM</b></h1>
            <div className="service">
                <div className="service_box_face">
                    <div className="service_box_item_face">
                        <img className="Service_image" src={preenchimento} alt=" Servicos de Preenchimento"/>
                        <p className="service_box_item_step_face">
                        <span>Preenchimento facial</span>
                        </p>
                        <p className="servico_p_face">O preenchimento com Ácido Hialurônico é um grande aliado e possui várias funcionalidades. A durabilidade dele vai de acordo com a região que é aplicada, no caso de um ano e meio a dois anos.<br /><br />Pode ser aplicado como:</p>
                        <ul class="check-list">
                            <li>Rinomodelação</li>
                            <li>Suavização de rugas e linhas de expressão</li>
                            <li>Reestruturação do contorno facial</li>
                            <li>Correção de cicatrizes de acne</li>
                        </ul>
                    </div>
                    <div className="service_box_item_face">
                        <img className="Service_image" src={botox} alt=" Servicos de Escleroterapia"/>
                        <p className="service_box_item_step_face">
                        <span>Botox</span>
                        </p>
                        <p className="servico_p_face">A função do botox é suavizar linhas de expressão e prevenir rugas.
Por conseguir prevenir, retardar o aparecimento das rugas, ele pode ser usado de forma preventiva.<br/><br/>

Ao ser aplicada, a Toxina Botulínica impede a liberação de uma substância chamada acetilcolina pelo neurônio, com isso, a contração dos músculos é bloqueada, o que impede a formação de rugas.</p>
                    </div>

                    <div className="service_box_item_face">
                        <img className="Service_image" src={Bioestimulador} alt=" Servicos de Bioestimulador "/>
                        <p className="service_box_item_step_face">
                        <span>Bioestimulador </span>
                        </p>
                        <p className="servico_p_face">O Bioestimulador de colágeno, que como o próprio nome diz, estimula naturalmente a produção de colágeno.<br /><br />

O procedimento é indicado para a recuperação do colágeno perdido ao longo dos anos e age recuperando a estrutura da pele, melhorando sustentação, flacidez, perdas de volumes em áreas específicas da face e corpo, enfim, todos os sinais de envelhecimento causados pela perda do colágeno.</p>
                    </div>
                </div>
            </div>

    <Entrecontato />
    <Footer />
    </>
  );
};
  
export default Face;