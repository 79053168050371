import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Ebook from "../../assets/images/ebook_image.png";
import Pdf from "../../documents/dr.Rodrigo-ebook-varizes.pdf";
import Servico from "../../components/servicos/Servicos";
import varizes_header from '../../assets/images/Varizes_Header.PNG';
import React from 'react';
import "./varizes.css";

const Varizes = () => {
  return (
    <>
    <Navbar /> 

    <section>
      <div className="image_header_section">
        <img className="image_header" src={varizes_header} alt="Varizes Header" style={{objectFit: 'cover', width: '100%', height: '80vh', marginTop: '3px'}}/>
          <h1 className="image_header_title">varizes<br/><h1>& microvarizes</h1></h1>
      </div>
    </section>

    <Servico />

    <section>
        <div className="ebook-section">
            <div className="ebook-description">
                <h3 className="ebook-gratuito">ebook gratuito!</h3>
                <h1 className="ebook-guia">Tudo sobre varizes:<br/>o guia completo</h1>
                <p className="ebook-info">As principais informações sobre esse problema em um único material!</p>
                <a href={Pdf} target='_blank' rel=''><button className="button-ebook" trailingIcon="picture_as_pdf" label="Resume">Baixe Agora</button></a>
            </div>
            <div className="ebook-image"> 
                <img src={Ebook} alt="Ebook image"/>
            </div>
        </div>
    </section>
        
    <Footer />
    </>
  );
};
  
export default Varizes;