import { FaBars } from 'react-icons/fa';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

/*Here is the entire NavBar */
export const Nav = styled.nav`
  background: #FFFFFF;
  box-shadow: 0 4px 2px -2px gray;
  height: 90px;
  display: flex;
  justify-content: center;
  padding: 0.2rem calc((100vw - 1000px) / 2);
  z-index: 12;
  /* Third Nav */
  /* justify-content: flex-start; */
`;
  
/*Here is just the NavBar elements, links etc */
export const NavLink = styled(Link)`
  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  cursor: pointer;
  &.active {
    color: #AB8A57;
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
    color: #AB8A57;
  }
`;
  
/*Here is the Hamburguer menu when screen is les that 768px*/
export const Bars = styled(FaBars)`
  display: none;
  color: #AB8A57;
  align-items: center;
  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 75%);
    font-size: 3rem;
    cursor: pointer;
  }
`;

/*Nav Bar menu design in mobile view */
export const NavMenuMobile = styled.div`
  a {
    color: white;
    padding: 10px;
  }
  a.active {
    color: white;
    font-weight: bold;
    color: #AB8A57
  }
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: absolute;
    top: 90px;
    right: 0;
    width: 300px;
    height: 100vh;
    background: #b5a998;
    padding: 40px 0 0 10px;
    z-index: 1000; 
}
`

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  /* Second Nav */
  /* margin-right: 24px; */
  /* Third Nav */
  /* width: 100vw;
  white-space: nowrap; */
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
  
export const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  margin-left: 60px;
  /* Third Nav */
  /* justify-content: flex-end;
  width: 100vw; */
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
  
/*Button style for the Contato Link */
export const NavBtnLink = styled(Link)`
  border-radius: 40px;
  background: #AB8A57;
  padding: 10px 22px;
  color: white;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  /* Second Nav */
  margin-left: 24px;
  border: #AB8A57 1px solid;
  &:hover {
    border: #AB8A57 1px solid;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    background: white;
    color: #AB8A57;
  }
`;