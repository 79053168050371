import React from "react";
import styled from "styled-components";
import { FaFacebook } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';

export const CardSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 3rem;
  margin: 5rem 3rem;  
`

const CardItem = styled.div`
  width: 39.5rem;
  background-color: #26918C;
  height: 460px;
  padding: 30px;
  margin: 0 2.35rem;
  transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in; 

  @media (max-width: 1367px) {
    margin: 3rem 5rem;
  }

  @media (max-width: 1460px) {
    margin: 3rem 5rem;
  }

  &:hover {
    background-color: #3ba8a2;
    border: none;
    box-shadow: 5px 5px 10px #4c4e4e;
  }
`

const Title = styled.h3`
  padding: 50px 0 10px 0;
  text-align: center;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
`

const CardParagraph = styled.p`
  align-self: center;
  font-size: 20px;
  text-align: center;
  color: white;
  height: 92px !important;

`
const Item = styled.section`
  display: flex;
  justify-content: center;
  margin-top: 4.35rem;
  column-gap: 50px;
`

const Button = styled.button`
  width: 180px;
  height: 43px;
  background-color: white;
  border: none;
  color: #AB8A57;
  text-transform: uppercase;
  padding: 5px;
  border-radius: 3rem;

  &:hover {
    background-color: #e6dfdf;
  }

  &:active {
    background-color: #ffffff;
  }
`

const Link = styled.a`
  text-decoration: none;
  color: #AB8A57;

  :hover {
    text-decoration: none;
    color: #AB8A57;
  }

  :active {
    text-decoration: none;
    color: #AB8A57;
  }

  :visited {
    text-decoration: none;
    color: #AB8A57;
  }
`

function Card(props){

  function renderButton() {
    if(props.button){
      return(
        <Link href={`${props.email ? "mailto:" : ""}${props.href}`} target="_blank"><Button>{props.button}</Button></Link>
      )
    }
  }

  function renderIcons() {
    if(props.type === "SocialMedia"){
      return(
        <>
          <a href="https://www.facebook.com/clinicadrrodrigomorais?mibextid=LQQJ4d" target="_blank" rel="noreferrer"><FaFacebook size="60px" style={{color: "white"}}/></a>
          <a href="https://www.instagram.com/clinicadrrodrigomorais/" target="_blank" rel="noreferrer"><FaInstagram size="60px" style={{color: "white"}}  /></a>
        </>
      )
    }
  }

  return (
    <>
      <CardItem>
        <Title>{props.title}</Title>
        <CardParagraph>{props.paragraph}</CardParagraph>
        <Item>
          {renderButton()}
          {renderIcons()}
        </Item>
      </CardItem>
    </>
  )
}

export default Card;