import React from 'react';
import Navbar from '../../components/navbar/Navbar';
import Footer from "../../components/footer/Footer";
import Blogbanner from '../../assets/images/botox-foto-depilacao-1080x720.jpg';
import Entrecontato from '../../components/entrecontato/Entrecontato';
import MediaCard from '../../components/blog/Blog';

const Blog = () => {
  return (
    <>
      <Navbar />
      
      <section>
        <div className="image_header_section">
          <img className="image_header" src={Blogbanner} alt="Varizes Header" style={{objectFit: 'cover', width: '100%', height: '80vh', marginTop: '3px'}}/>
          <h1 className="image_header_title">blog</h1>
        </div>
      </section>

      <h1 className="servicos_h1" style={{color: '#3E3E3E', textTransform: 'none'}}>Acesse o material<br/><b style={{color: '#535353', textTransform: 'uppercase'}}>clicando em saiba mais</b></h1>

      <MediaCard />

      <Entrecontato />
      
      <Footer />
    </>
  );
};
  
export default Blog;