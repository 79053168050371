import * as React from 'react';
import CardActions from '@mui/material/CardActions';
import blog1 from '../../assets/images/blog1.jpg';
import blog2 from '../../assets/images/blog2.jpg';
import blog3 from '../../assets/images/blog3.jpg';
import blog4 from '../../assets/images/blog4.jpg';
import blog5 from '../../assets/images/blog5.jpg';
import blog6 from '../../assets/images/blog6.jpg';
import styled from 'styled-components';

export const Card = styled.div`
  max-width: 330px; 
  background-color: #f4f9f9;
  box-shadow: 5px 5px 10px #d0d6d6;
  margin: 2rem;
  transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in; 
  &:hover {
    border: none;
    box-shadow: 2px 2px 8px #4c4e4e;
  }
`

export const CardContent = styled.div`
  margin: 3rem 3rem;  
`
export const CardMedia = styled.div`
  height: 0px;
`

const Typography = styled.div`
  text-align: left;
  color: #54524e;
`
export const Button = styled.div`
  background-color: #e8e7e3;
  border: 1px white solid;
  color: #AB8A57;
  padding: 8px 12px 8px 12px;
  border-radius: 10vh;
  margin-left: 20px;
  margin-bottom: 10px;
  transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  &:hover {
    cursor: pointer;
    color: white;
    background-color: #AB8A57;
    border: 1px #AB8A57 solid;
  }
`

export default function MediaCard() {
  return (
    <>
    <div className='all_cards' style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
          columnGap: '2rem',
          margin: '5rem'
    }}>
    <Card>
      <CardMedia/>
      <img className='blog_image' src={blog1} alt="Benefícios da vitamina c " style={{objectFit: 'cover', width: '330px', height: '300px'}}/>
      <CardContent>
        <Typography style={{color: '#AB8A57', padding: '10px 0px', textTransform: 'uppercase'}} gutterBottom variant="h5" component="div">
        Benefícios da vitamina c 
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Essa vitaminia funciona como antioxidante e auxilia combatendo a ação dos radicais livres.
        </Typography>
      </CardContent>
      <CardActions>
        <a href='https://www.instagram.com/p/CoIUr9_p0A5/?igshid=MzRlODBiNWFlZA==' target='_blank'><Button size="large">Saiba Mais</Button></a>
      </CardActions>
    </Card>

    <Card >
      <CardMedia/>
      <img className='blog_image' src={blog2} alt="Harmonização Facial" style={{objectFit: 'cover', width: '330px', height: '300px'}}/>
      <CardContent>
        <Typography style={{color: '#AB8A57', padding: '10px 0px', textTransform: 'uppercase'}} gutterBottom variant="h5" component="div">
        Harmonização Facial 
        </Typography>
        <Typography variant="body2" color="text.secondary">
        É um conjunto de procedimentos estéticos combinados para mehorar a harmonia do rosto.
        </Typography>
      </CardContent>
      <CardActions>
        <a href='https://www.instagram.com/p/Cry8b8hJOKV/?igshid=MzRlODBiNWFlZA==' target='_blank'><Button size="large">Saiba Mais</Button></a>
      </CardActions>
    </Card>

    <Card >
      <CardMedia/>
      <img className='blog_image' src={blog3} alt="Escleroterapia " style={{objectFit: 'cover', width: '330px', height: '300px'}}/>
      <CardContent>
        <Typography style={{color: '#AB8A57', padding: '10px 0px', textTransform: 'uppercase'}} gutterBottom variant="h5" component="div">
        Escleroterapia 
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Um procedimento minimanente invasivo, que apresenta resultados rápidos e eficientes para tratar varizes.
        </Typography>
      </CardContent>
      <CardActions>
        <a href='https://www.instagram.com/p/CrmDFWspDT-/?igshid=MzRlODBiNWFlZA==' target='_blank'><Button size="large">Saiba Mais</Button></a>
      </CardActions>
    </Card>

    <Card >
      <CardMedia/>
      <img className='blog_image' src={blog4} alt="Botox Preventivo" style={{objectFit: 'cover', width: '330px', height: '300px'}}/>
      <CardContent>
        <Typography style={{color: '#AB8A57', padding: '10px 0px', textTransform: 'uppercase'}} gutterBottom variant="h5" component="div">
        Botox Preventivo
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Esse procedimento consegue retardar os sinais de idade, rejuvenescer a pele e diminuir a aparência das rugas.
        </Typography>
      </CardContent>
      <CardActions>
        <a href='https://www.instagram.com/p/CrJtwvHpkYY/?igshid=MzRlODBiNWFlZA==' target='_blank'><Button size="large">Saiba Mais</Button></a>
      </CardActions>
    </Card>

    <Card >
      <CardMedia/>
      <img className='blog_image' src={blog5} alt="Cuidados com a pele outono" style={{objectFit: 'cover', width: '330px', height: '300px'}}/>
      <CardContent>
        <Typography style={{color: '#AB8A57', padding: '10px 0px', textTransform: 'uppercase'}} gutterBottom variant="h5" component="div">
        Cuidados com a pele outono 
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Com a troca de temperatura, devemos ter alguns cuidados também com a nossa pele, confere as dicas!
        </Typography>
      </CardContent>
      <CardActions>
        <a href='https://www.instagram.com/p/Cq8wVM_JjUY/?igshid=MzRlODBiNWFlZA==' target='_blank'><Button size="large">Saiba Mais</Button></a>
      </CardActions>
    </Card>

    <Card >
      <CardMedia/>
      <img className='blog_image' src={blog6} alt="olheiras" style={{objectFit: 'cover', width: '330px', height: '300px'}}/>
      <CardContent>
        <Typography style={{color: '#AB8A57', padding: '10px 0px', textTransform: 'uppercase'}} gutterBottom variant="h5" component="div">
        olheiras? 
        </Typography>
        <Typography variant="body2" color="text.secondary">
        Ninguém gosta de ter manchas escuras e/ou profundas abaixo dos ollhos dando aspecto de velhice e cansaço.
        </Typography>
      </CardContent>
      <CardActions>
        <a href='https://www.instagram.com/p/CqEKdmbpEJq/?igshid=MzRlODBiNWFlZA==' target='_blank'><Button size="large">Saiba Mais</Button></a>
      </CardActions>
    </Card>
    </div>
    </>
  );
}