import React from "react";
import { Route, Routes } from "react-router-dom";
import ROUTES from "./ROUTES";

import { default as Home } from "../pages/home/Home";
import { default as Blog } from "../pages/blog/Blog";
import { default as Contact } from "../pages/contact/Contact"
import { default as Body } from "../pages/body/Body";
import { default as Face } from "../pages/face/Face";
import { default as Varizes } from "../pages/varizes/Varizes";

export default () => {
  return (
    <Routes>
      <Route 
        path={ROUTES.HOME}
        element={<Home />}
      />
      <Route 
        path={ROUTES.BLOG} 
        element={<Blog />}
      />
      <Route 
        path={ROUTES.CONTACT} 
        element={<Contact />}
      />
      <Route 
        path={ROUTES.BODY} 
        element={<Body />}
      />
      <Route 
        path={ROUTES.FACE} 
        element={<Face />}
      />
      <Route 
        path={ROUTES.VARIZES} 
        element={<Varizes />}
      />
    </Routes>
  )
};