import React from "react";
import Footer from "../../components/footer/Footer";
import 'bootstrap/dist/css/bootstrap.css';
import filosofia from '../../assets/images/banner-concept-yellow-1080x675.jpg';
import cirurgicaWhite from "../../assets/images/cirurgica-white.png";
import clinicaWhite from "../../assets/images/clinica-white.png";
import esteticaWhite from "../../assets/images/estetica-white.png";
import varizesWhite from "../../assets/images/varizes-white.png";
import paciente from "../../assets/images/dr_com_paciente.jpeg";
import equipe from "../../assets/images/equipe_toda.jpeg";
import visitar from "../../assets/images/venhanosvisitar.jpeg";
import Carousel from '../../components/carousel/Carousel';
import Navbar from '../../components/navbar/Navbar';

import "./style.css";
import { WhatsApp } from "@mui/icons-material";

function Home() {
  return(
    <>
      <Navbar />
      <Carousel />

    <div className="icon-menu">
        <a href="https://wa.me/5511996034449" target="_blank" rel="noreferrer"><WhatsApp sx={{ fontSize: 60 }}/></a>
    </div>
    <section id="team">
        <div className="container my-3 py-5 text-center">
            <div className="row mb-5">
                <div className="col">
                    <h1 className="especialidade-title">ESPECIALIDADES</h1>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-3 col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <img src={cirurgicaWhite} style={{img: '20vh', margin: '30px'}} alt="img cirurgica"/>
                            <h3 className="especialidade-card-title">DERMATOLOGIA CIRURGICA</h3>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <img src={esteticaWhite} style={{img: '20vh', margin: '30px'}} alt="img cirurgica"/>
                            <h3 className="especialidade-card-title">DERMATOLOGIA ESTETICA</h3>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <img src={clinicaWhite} style={{img: '20vh', margin: '30px'}} alt="img cirurgica"/>
                            <h3 className="especialidade-card-title">DERMATOLOGIA CLINICA</h3>
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <img src={varizesWhite} style={{img: '20vh', margin: '30px'}} alt="img cirurgica"/>
                            <h3 className="especialidade-card-title">VARIZES E MICROVARIZES</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section>
        <div className="entrar_em_contato">
                <img className="image_header_contato" src={filosofia} alt="Varizes Header" style={{objectFit: 'cover', width: '100%', height: '60vh'}}/>
                <h1 className="encontre_h2">A filosofia da clínica RM é alcançar os melhores resultados, disponibilizando tratamentos não invasivos, de alta tecnologia e reconhecidos no meio científico.</h1>
        </div>
    </section>

    <section>
        <h1 className="especialidade-title" style={{fontsize: '40px', color: '#AB8A57', margin: '8vh', textalign: 'center', textTransform: "uppercase"}}>conheça nosso time</h1>
        <div className="container-image-gallery">
            <div className="col-grid">
                <div className="big-stretch">
                    <img src={visitar} className="gallery-img" alt="visitar"/>
                </div>
                
                <div className="v-stretch">
                    <img src={paciente} className="gallery-img" alt="paciente"/>
                </div>
            </div>

            <div className="col-grid">
                <div className="h-stretch">
                    <img src={equipe} className="gallery-img" alt="equipe"/>
                </div>

                <div className="big-stretch">
                    <img src={visitar}  className="gallery-img" alt="visitar"/>
                </div>
            </div>
        </div>
    </section>

    <Footer/>
    </>
  )
}

export default Home;