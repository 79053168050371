import React from "react";
import contato from '../../assets/images/woman-02-green-1080x675.jpg'
import './entre.css';

const Entrecontato = () => {
    return (
        <>
            <div className="entrar_em_contato">
                <img className="image_header_contato" src={contato} alt="Varizes Header" style={{objectFit: 'cover', width: '100%', height: '60vh'}}/>
                <h1 className="encontre_h1">Quer saber mais?<br/><b>entre em contato conosco!</b></h1>
                <center>
                <button className="button_em_contato"><a href="https://wa.me/5511996034449" className="a_contato" target="_blank">Entre em contato</a></button>
                </center>
            </div>
        </>
    )
}

export default Entrecontato;