import React from "react";
import { Helmet } from "react-helmet";
import Logo from '../../src/assets/images/logo.jpg';

import RouterConfig from "../navigation/RouterConfig";

const MetaTags = () => (
  <Helmet>
    <meta charSet="UTF-8"/>
    <meta content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
    name="viewport"
    />
    <meta property="og:type" content="website"></meta>
    <meta property="og:title" content="Dr Rodrigo Morais"></meta>
    <meta property="og:description" content="Na clínica RM você encontra diversos tratamentos estéticos para cuidar da saúde e da beleza."></meta>
    <meta property="og:image" content={Logo}></meta>
    <meta 
      name="keywords"
      content="esclero
      ,Protetor solar
      ,sol 
      ,verão
      ,inverno
      ,outono
      ,estetica 
      ,dermato
      ,dermatologista
      ,dermatologia 
      ,pele 
      ,bioestimulador 
      ,colageno
      ,preenchimento
      ,botox
      ,Estria
      ,celulite
      ,Peeling
      ,limpeza de pele
      ,Redução de medidas
      ,emagrecer
      ,emagrecimento 
      ,Beleza
      ,fotodepilação
      ,laser de co2
      ,Sculptra
      ,radiesse
      ,elleva
      ,galderma 
      ,mers 
      ,belotero 
      ,Radiesse 
      ,fios de pdo
      ,Varizes
      ,pelo
      ,manchas
      ,melasma 
      ,sol
      ,skincare"
    />
    <meta http-equiv="Content-Language" content="pt-br, en" />
  </Helmet>
)

export default () => {
  return (
    <>
      <MetaTags />
      <RouterConfig />
    </>
  )
}