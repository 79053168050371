import React from "react";
import Logo from "../../assets/images/logo-transparent.png";
import footer from "../../components/footer/footer.css";

const Footer=()=> {
    return (
        <div className="footer">
            <div className="sb__footer section__padding">
                <div className="sb__footer-links">
                    <div className="sb__footer-links-div">
                        <h4 className="footer-h4">ESTAMOS LOCALIZADOS NA:</h4>
                            <a href="https://goo.gl/maps/UXkw7KeBhWup3ToQ8" target="_blank">
                                <p className="footer-p">Rua Tenente Gomes Ribeiro, 182, cj 114, Vila Mariana (Próx. Metrô Sta. Cruz)
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
  <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
</svg></p>
                            </a>
                            <a href="https://goo.gl/maps/kqkL8kGVPJJAVDQDA" target="_blank">
                                <p className="footer-p">Rua Jurubatuba, 1350, cjs. 1107,1108,1109, São Bernardo do Campo
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"/>
  <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"/>
</svg></p>
                            </a>
                    </div>
                    <div className="sb__fotter-links_div">
                        <h4 className="footer-h4">CONTATOS:</h4>
                            <a href="https://wa.me/551141234449" target="_blank">
                                <p className="footer-p">(11) 4123-4449 / 4121-83974</p>
                            </a>
                            <a href="https://wa.me/5511996034449" target="_blank">
                                <p className="footer-p">(11) 99603-4449</p>
                            </a>
                                <p className="footer-p">RESPONSÁVEL TÉCNICO<br/>Dr. Rodrigo Morais Romão</p>
                        
                                <p className="footer-p">Dermatologia</p>
                            
                    </div>
                    <div className="sb__footer-links_div">
                        <div className="Logo">
                            <img src={Logo} alt="logo" className="footer-img"/>
                        </div>
                    </div>
                </div>

                <hr>
                </hr>

                <div className="sb__footer-below">
                    <div className="sb__footer-copyright">
                        <p className="footer-p">
                            @{new Date().getFullYear()} Dr Rodrigo Morais All right reserved.
                        </p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer;