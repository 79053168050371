import Navbar from '../../components/navbar/Navbar';
import Footer from "../../components/footer/Footer";
import fotodepilacao from "../../assets/images/fotodepilacao-1024x683.jpg";
import mtiel from "../../assets/images/mtiel.jpeg";
import bodybanner from "../../assets/images/corpo.png";
import Entrecontato from '../../components/entrecontato/Entrecontato';
import React from 'react';
  
const Body = () => {
  return (
    <>
    <Navbar />

    <section>
      <div className="image_header_section">
        <img className="image_header" src={bodybanner} alt="Varizes Header" style={{objectFit: 'cover', width: '100%', height: '80vh', marginTop: '3px'}}/>
          <h1 className="image_header_title">corpo</h1>
      </div>
    </section>

    <h1 className="servicos_h1">Conheça alguns dos<br/><b>nossos serviços e como funcionam</b></h1>
            <div className="service">
                <div className="service_box">
                    <div className="service_box_item">
                        <img className="Service_image" src={fotodepilacao} alt=" Servicos de Fotodepliação"/>
                        <p className="service_box_item_step">
                            <span>Fotodepliação</span>
                        </p>
                        <p className="servico_p">A fotodepilação funciona através da luz pulsada intensa (IPL). Durante o procedimento acontece a cauterização progressiva das células que produzem os pelos.</p>
                        <ul class="check-list">
                            <li>Aumento da produção de colágeno</li>
                            <li>Combater a foliculite</li>
                        </ul>
                    </div>
                    <div className="service_box_item">
                        <img className="Service_image" src={mtiel} alt=" Servicos de Velashape"/>
                        <p className="service_box_item_step">
                        <span>mtiel</span>
                        </p>
                        <p className="servico_p">O MTIEL é o nosso protocolo intensivo e exclusivo de emagrecimento aqui da clínica, e o melhor de tudo, sem cirurgia!
E utilizado dois aparelhos, um deles com o foco em perca de gordura viceral e outro em queimar essa gordura que foi liberada.
Juntamente com os aparelhos é utilizado um medicamento que acelera o resultado do tratamento.</p>
                    </div>
                </div>
            </div>

    <Entrecontato />
    <Footer />
    </>
  );
};
  
export default Body;