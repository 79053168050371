import React from "react";
import HeroSlider, { Slide } from 'hero-slider';
import slide1 from '../../assets/images/drenagem-linfatica-1024x576.jpeg';
import slide2 from '../../assets/images/clinic_header.jpeg';
import slide3 from '../../assets/images/tipos-de-varizes-1080x719.jpg';

const Carousel = () => {
    return (
        <HeroSlider
            height={"80vh"}
            slidingAnimation="left-to-right"
            orientation="horizontal"
            initialSlide={2}
            onBeforeChange={(previousSlide, nextSlide) => console.log("onBeforeChange", previousSlide, nextSlide)}
            onChange={nextSlide => console.log("onChange", nextSlide)}
            onAfterChange={nextSlide => console.log("onAfterChange", nextSlide)}
            style={{
                backgroundColor: "#AB8A57",
            }}
            autoplay
            setting={{        
                slidingDuration: 500,
                slidingDelay: 100,
                shouldAutoplay: true,
                shouldDisplayButtons: true,
                autoplayDuration: 2000,
            }}
        >
            
            <Slide 
                background={{
                    backgroundImageSrc: slide2,
                    backgroundAttachment: "cover",
                }}
                            
            />
            
            <Slide 
                background={{
                    backgroundImageSrc: slide1,
                    backgroundAttachment: "cover",
                }}
            />

            <Slide 
                background={{
                    backgroundImageSrc: slide3,
                    backgroundAttachment: "cover",
                }}
            />
        </HeroSlider>
    )
}

export default Carousel;