import React from "react";
import style from "./Service.css";
import beneficios from "../../assets/images/beneficios.jpeg";
import esleroterapia from "../../assets/images/beneficiosescleroterapia.png"

const Servico = () => {
    return (
        <>
        <h1 className="servicos_h1">Conheça alguns dos<br/><b>NOSSOS SERVIÇOS E COMO FUNCIONAM</b></h1>
            <div className="service">
                <div className="service_box">
                    <div className="service_box_item">
                        <img className="Service_image" src={esleroterapia} alt=" Servicos de Escleroterapia"/>
                        <p className="service_box_item_step">
                        <span>Escleroterapia</span>
                        </p>
                        <p className="servico_p">A escleroterapia é um tipo de tratamento indicado para tratar de varizes e vasinhos nas pernas, sem a necessidade de realizar cirurgias e procedimentos mais invasivos.<br /><br />
                        Além de produzir excelente resultado estético, a escleroterapia também elimina o incômodo causado, como dores constantes e inchaço.</p>
                    </div>
                    <div className="service_box_item">
                        <img className="Service_image" src={beneficios} alt=" Servicos de Escleroterapia beneficios"/>
                        <p className="service_box_item_step">
                        <span>beneficios</span>
                        </p>
                        <p className="servico_p">A nossa técnica é diferente de todas que se encontram no mercado. Não utilizamos glicose ou espuma, nosso produto é de origem alemã e não possui contraindicação.<br /><br />Listamos abaixo os benefícios de aplicar a Escleroterapia:</p>
                        <ul class="check-list">
                            <li>Alívio nas dores.</li>
                            <li>Sem necessidade de procedimentos cirúrgicos.</li>
                            <li>Diabeticos podem realizar esse procedimento.</li>
                            <li>Qualidade de vida e aumento da autoestima.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )    
}

export default Servico;